import { AppVerification } from './../shared/models/app-verification.model';
import { Deal } from './../shared/models/deal.model';
import { HttpService } from './../core/services/http.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { take } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class DealsService {

  change$: Subject<any> = new Subject();
  uuid: string;
  salesAccountId: string;
  agentId: string;
  appVerificationId: string;

  private itemDoc: AngularFirestoreDocument<AppVerification>;
  item: Observable<AppVerification>;

  get onChange(): Observable<any> {
    return this.change$.asObservable();
  }

  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private afs: AngularFirestore,
  ) { }

  validate(dealId: string) {
    const httpOptions = this.httpService.getHeaders();
    return this.http.get(`${environment.apiUrl}/deals-viewAppVerification/${dealId}`, httpOptions);
  }

  getAppVerification(appId: string): Observable<any> {
    this.uuid = appId;
    return this.afs.doc<AppVerification>(`app-verification/${appId}`).valueChanges().pipe(take(1));
    // return this.afs.doc<AppVerification>(`app-verification/${appId}`).get().pipe(
    //   map(response => {
    //     this.deal = response;
    //     return this.deal;
    //   }),
    //   catchError(error => {
    //     const message = (error.error || error).message;
    //     return message;
    //   }))
  }

  calculateCommission() {
    // rent amount x rate multiplier
    // if flat/gift card

  }

  save(deal: Deal) {
    const httpOptions = this.httpService.getHeaders();
    deal.uuid = this.uuid;
    return this.http.post(`${environment.apiUrl}/deals-saveAppVerification`, {data: deal}, httpOptions);
  }
}
