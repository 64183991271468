import { DealsService } from './deals.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DealResolverService implements Resolve<any> {

  constructor(private deals: DealsService) { }

  // [TODO]: COMPLETE WITH AUTH
  // resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
  //   const dealId = route.paramMap.get('dealId');
  //   this.deals.appVerificationId  = dealId;
  //   return dealId;
  // }

  // [no auth]
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const dealId = route.paramMap.get('dealId');
    return this.deals.getAppVerification(dealId);
  }
}
