<div class="page">
  <div class="container">
    <div class="page-header text-center">
      <p class="text-overline color--primary">Dashboard</p>
    </div>

    <mat-card class="mb-2">
      <h2>Application Verifications</h2>
    </mat-card>
  </div>
</div>
