import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  httpOptions: any;

  constructor() {
    this.setHeaders();
  }

  setHeaders() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': environment.host,
        'Content-Type':  'application/json',
      }),
      responseType: 'json' as const
    };
  }

  getHeaders() {
    return this.httpOptions;
  }

}
