// import { SecureGuard } from './core/guards/secure.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { AuthGuard } from '@core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./lead/lead.module').then(m => m.LeadModule)
  },
  {
    path: 'client',
    loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
  },
  {
    path: 'leasing',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./leasing/leasing.module').then(m => m.LeasingModule)
  },
  // {
  //   path: 'auth',
  //   canActivate: [SecureGuard],
  //   loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
