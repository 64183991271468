import { SharedModule } from '@shared/shared.module';
import { NgModule } from '@angular/core';
import { LeasingRoutingModule } from './leasing-routing.module';
import { LeasingComponent } from '../leasing/leasing.component';
import { AppVerificationComponent } from '../leasing/app-verification/app-verification.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompleteComponent } from './complete/complete.component';


@NgModule({
  declarations: [LeasingComponent, AppVerificationComponent, DashboardComponent, CompleteComponent],
  imports: [
    SharedModule,
    LeasingRoutingModule
  ]
})
export class LeasingModule { }
