import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
// import { AuthService } from '@core/services/auth.service';
// import { EventsService } from '@core/services/events.service';
import { routeAnimations } from '../animations';

@Component({
  selector: 'aoh-leasing',
  templateUrl: './leasing.component.html',
  styleUrls: ['./leasing.component.scss'],
  animations: [
    routeAnimations
  ]
})
export class LeasingComponent {
  user: any;
  subscription: any;
  // constructor(
  //   public auth: AuthService,
  //   public events: EventsService) {
  //     this.subscription = this.auth.onChange.subscribe(() => {
  //       this.user = this.auth.user;
  //     })
  // }

  // ngOnInit(): void {
  //   this.subscription = this.auth.onChange.subscribe(() => {
  //     this.user = this.auth.user;
  //   })

  // }
  // ngDoCheck() {
  //   this.user = this.auth.user;
  // }

  // ngOnDestroy() {
  //   if (this.subscription) {
  //     this.subscription.unsubscribe();
  //   }
  // }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

}
