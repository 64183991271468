// import { EventsService } from './../../core/services/events.service';
// import { AuthService } from '@core/services/auth.service';
import { Component, Input, OnInit } from '@angular/core';
// import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'aoh-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  user: any;
  subscription: any;

  // constructor(
  //   private analytics: AngularFireAnalytics,
  //   public auth: AuthService,
  //   public events: EventsService
  // ) {
  //   this.auth.onChange.subscribe(() => {
  //     this.user = this.auth.user;
  //   })
  // }

  // ngOnInit(): void {

  // }
  // ngOnChanges() {

  // }
  // contact() {
  // }

  // signOut() {
  //   this.auth.signOut();
  //   this.user = null;
  // }

}
