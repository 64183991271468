import { CompleteComponent } from './complete/complete.component';
import { DealResolverService } from './deal-resolver.service';
import { AppVerificationComponent } from './app-verification/app-verification.component';
import { LeasingComponent } from './leasing.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { AuthGuard } from '../core/guards/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';


const routes: Routes = [
  {
    path: '',
    component: LeasingComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {animation: 'FadeIn'}
      },
      {
        path: 'app-verification/:dealId',
        component: AppVerificationComponent,
        data: {animation: 'FadeIn'},
        resolve: {
          deal: DealResolverService
        },
      },
      {
        path: 'complete',
        component: CompleteComponent,
        data: {animation: 'FadeIn'}
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeasingRoutingModule { }
