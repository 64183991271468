import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd, RouterOutlet } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { routeAnimations } from './animations';

@Component({
  selector: 'aoh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    routeAnimations
  ]
})
export class AppComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
  ) {
    const appTitle = this.title.getTitle();
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.route.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }

          if (child.snapshot.data.title) {
            return child.snapshot.data.title;
          }

          return appTitle;
        })
      )
      .subscribe((pageTitle: string) => {
        if (pageTitle !== 'Apartments of Houston') {
          this.title.setTitle(`${pageTitle} | Apartments of Houston`);
        }
      });
    }

    prepareRoute(outlet: RouterOutlet) {
      return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
    }
}
