<div class="page">
  <div class="container">
    <div class="page-header text-center">
      <p class="text-overline color--primary">Application Verification
      </p>
      <h1 class="mat-display-1">Please verify tenant application</h1>
    </div>
    <mat-card class="mb-2">
      <p class="text-body1"><strong>Client Name: </strong>{{ customer?.display_name }}</p>
      <p class="text-body1"><strong>Agency: </strong> Apartments of Houston</p>
      <p class="text-body1"><strong>Apartment Locator: </strong>{{ agent?.name }}</p>
    </mat-card>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <mat-card class="card-form">

        <section formGroupName="custom_field">
          <p class="text-overline">Tenant Information</p>
          <p class="mat-subheading-1">Please confirm the tenant's legal name on the application</p>
          <mat-form-field>
            <mat-label>Applicant Name</mat-label>
            <input
              matInput
              type="text"
              formControlName="cf_applicant_name"
              required/>
            <mat-error *ngIf="form.get('custom_field.cf_applicant_name').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>
          <div class="pb-2">
            <mat-checkbox class="toggle" [value]="hasCoapplicant" (change)="hasCoapplicant = !hasCoapplicant">Has Co-applicant</mat-checkbox>
            <mat-form-field *ngIf="hasCoapplicant">
              <mat-label>Co-applicant Name</mat-label>
              <input
                matInput
                type="text"
                formControlName="cf_coapplicant_name"
                required/>
            </mat-form-field>
          </div>

          <mat-divider></mat-divider>

          <p class="text-overline mt-2">Lease Information</p>
          <section fxLayout="row" fxLayout.xs="column" fxLayoutGap="8px">
            <mat-form-field>
              <mat-label>Unit Number</mat-label>
              <input
                matInput
                type="text"
                formControlName="cf_unit_number"
                required/>
              <mat-error *ngIf="form.get('custom_field.cf_unit_number').hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Move-in Date</mat-label>
              <input (focus)="moveinDate.open()" matInput [matDatepicker]="moveinDate" [min]="minDate"
              formControlName="cf_move_in_date" required>
              <mat-datepicker-toggle matSuffix [for]="moveinDate"></mat-datepicker-toggle>
              <mat-datepicker touchUi #moveinDate ></mat-datepicker>
              <mat-error *ngIf="form.get('custom_field.cf_move_in_date').hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </section>

          <mat-form-field>
            <mat-label>Lease Term</mat-label>
            <mat-select formControlName="cf_lease_term">
              <mat-option *ngFor="let term of leaseTerms" [value]="term">{{term}} Months</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('custom_field.cf_lease_term').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Rent Amount</mat-label>
            <input type="tel" matInput formControlName="cf_rent_amount" placeholder="$1000" inputmode="numeric" prefix="$" mask="separator"
            thousandSeparator="," required/>
            <mat-error *ngIf="form.get('custom_field.cf_rent_amount').hasError('required')">
              This field is required
            </mat-error>
          </mat-form-field>

          <mat-divider></mat-divider>
          <p class="text-overline mt-2">Commission Information</p>
          <mat-radio-group aria-label="Commission Type" [value]="selectedCommissionType">
            <mat-radio-button value="percent">Commission Rate Multiplier</mat-radio-button>
            <mat-radio-button value="flat">Flat Rate or Gift Card</mat-radio-button>
          </mat-radio-group>
          <section *ngIf="selectedCommissionType == 'percent'">
            <mat-form-field>
              <mat-label>Commission Multiplier</mat-label>
              <input
                matInput
                formControlName="cf_commission_multiplier"
                inputmode="numeric"
                mask="000"
                required/>
                <span matSuffix>%</span>
              <mat-error *ngIf="form.get('custom_field.cf_commission_multiplier').hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </section>

          <section *ngIf="selectedCommissionType == 'flat'">
            <mat-form-field>
              <mat-label>Amount</mat-label>
              <input
                matInput
                formControlName="cf_commission_flat"
                inputmode="numeric"
                prefix="$"
                mask="00000"
                thousandSeparator=","
                required/>
              <mat-error *ngIf="form.get('custom_field.cf_commission_flat').hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </section>
          <mat-divider></mat-divider>
          <div class="line-item">
            <p class="mat-headline">Total Commission Amount</p>
            <p class="mat-title color--primary"><strong>$2000</strong></p>
          </div>

        </section>
        <button mat-flat-button color="primary" class="full" type="submit" [disabled]="form.invalid">Submit</button>
        <div class="spinner-overlay" *ngIf="inProgress">
          <mat-spinner class="spinner" diameter="44"></mat-spinner>
        </div>
      </mat-card>
    </form>
  </div>
</div>
