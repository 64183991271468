export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCGYkO58tG5jt5kZb3fD6SKtM5ueIg6xLQ',
    authDomain: 'aptsofhou-dev.firebaseapp.com',
    databaseURL: 'https://aptsofhou-dev.firebaseio.com',
    projectId: 'aptsofhou-dev',
    storageBucket: 'aptsofhou-dev.appspot.com',
    messagingSenderId: '284712957139',
    appId: '1:284712957139:web:d52206648f68a40df8d026',
    measurementId: 'G-E2D34YC1NZ'
  },
  useEmulator: false,
  enableLogs: true,
  apiUrl: 'https://us-central1-aptsofhou-dev.cloudfunctions.net',
  host: 'https://app-dev.apartmentsofhouston.com',
  freshsales: {
    leadSource: {
      instagram: 13000379938,
      agentReferral: 13000379936
    },
    leadStageId: 13000174958,
    dealStageId: 13000201228
  },
  AGENT: {
    13000036605: {
      name: 'Tom Huynh',
      email: 'tom@suitedcode.io',
      licenseId: '555555'
    },
    13000044105: {
      name: 'Kevin Tran',
      email: 'kevin@apartmentsofhouston.com',
      licenseId: '716284'
    },
    13000036602: {
      name: 'Adam Hunt',
      email: 'adam@apartmentsofhouston.com',
      licenseId: '676787'
    }
  }
};
