import { DealsService } from './../deals.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { environment } from '@env/environment';
import * as _ from 'lodash';

@Component({
  selector: 'aoh-app-verification',
  templateUrl: './app-verification.component.html',
  styleUrls: ['./app-verification.component.scss'],
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }]
})
export class AppVerificationComponent implements OnInit {

  form: FormGroup;
  inProgress: boolean;
  customer: any;
  agent: any;
  dealId: any;
  minDate: Date;
  hasCoapplicant = false;
  commissionType: string[] = ['percent', 'flat'];
  selectedCommissionType = 'percent';
  leaseTerms: string[] = ['12', '6', '9', '13', '14', '15+'];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private deal: DealsService
  ) {
    this.minDate = new Date();
    this.route.data
      .subscribe(data => {
        console.log({data});
        this.customer = data.deal.contact;
        this.agent = _.get(environment.AGENT, data.deal.agentId);
        this.dealId = data.deal.deal_id;
      }, (error) => {
        console.log(error);
      });
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      custom_field: new FormGroup({
        cf_applicant_name:  new FormControl(this.customer.display_name, [Validators.required]),
        cf_coapplicant_name:  new FormControl(),
        cf_lease_term: new FormControl('12', [Validators.required]),
        cf_commission_multiplier:  new FormControl('', [Validators.required]),
        cf_commission_flat:  new FormControl(''),
        cf_move_in_date:  new FormControl(this.minDate, [Validators.required]),
        cf_rent_amount: new FormControl('', [Validators.required]),
        cf_unit_number:  new FormControl('', [Validators.required]),
        cf_total_commission_amount:  new FormControl(''),
      }),
    });
  }

  submit() {
    this.inProgress = true;
    const deal = this.form.getRawValue();
    deal.id = this.dealId;
    console.log(deal);

    this.deal.save(deal).subscribe(() => {
      console.log('successsful');
      this.redirect();
      this.inProgress = false;
    }, () => {
      this.inProgress = false;
    });
  }
  redirect() {
    this.inProgress = false;
    this.router.navigate(['/leasing/complete'], {
      relativeTo: this.route.parent
    });
  }

}
