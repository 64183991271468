<header class="site-header">
  <div class="site-header-left">

  </div>
  <div class="site-header__logo">
    <img src="/assets/logo_apartments-of-houston.png" class="logo"/>
  </div>
  <!-- <div class="site-header-right" *ngIf="auth.isLoggedIn">
    <button mat-flat-button [matMenuTriggerFor]="menu" color="primary"><mat-icon>menu</mat-icon></button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="signOut()">Log Out</button>
    </mat-menu>
  </div> -->
</header>
